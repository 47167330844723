.background_white {
  background: white
}

.filterbutton{
margin-left:10px;
display: inline-block;
}

.downloadbutton{
float:right;
margin-bottom: 10px;
}

.chartbar{
border-right: 1px solid;
}

.impactcheckbox{
display: inline-block; 
width:50%;
font-size: 14px;
font-weight: bold;
}

.allClass{
  min-width: 130px;
}

.searchableLay{
  z-index: 1000; 
 }

 .input-box {
  display: flex;
  align-items: center;
}

.input-box .prefix {
  font-weight: 300;
  font-size: 14px;
  color: #999;
}

.rdl-filter{
  border-radius: 0.375rem;
}

.rdl-control{
  border-radius: 0.375rem;
}

.rdl-filter:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.rdl-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.center-content{
  align-items: center;
}

.group-content{
  display: contents;
}

