.searchbutton{
  position: absolute;
  bottom: 0px
}

.total_variant_found{
  float: left; 
  vertical-align: center
}

.impact{
  display: inline-block; 
  width:30%
}

.searchableLay{
 z-index: 1000; 
}

.cellwrapetext {
  display: block;
  width: 80px;
  padding-right: 5px;
  margin-left: -2px;
  word-break: keep-all;
  text-align: left;
}

